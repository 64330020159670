import React from 'react';

const Header = () => {
    return (
        <footer className="header">
            <h1>TRIPS</h1>
        </footer>
    );
};

export default Header;